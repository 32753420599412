<template>
  <div class="esmp-links block block--white block--rounded">
    <div class="block__row">
      <div class="block__title">
        Ссылки
      </div>
      <esmp-button
        icon="plus"
        size="small"
        @click="addLink"
      />
    </div>
    <div class="esmp-links__list" v-if="links">
      <div
        class="esmp-links__item link"
        v-for="(link, index) in links"
        :key="index"
      >
        <div class="link__actions">
          <!--
          <esmp-switch
            class="link__switch"
            v-model="link.isDisabled"
          />
          -->
          <esmp-button
            @click="removeLink(index)"
            icon="trash"
            size="small"
            view="ghost"
          />
        </div>
        <div class="link__inner">
          <div class="link__options">
            <div class="link__option">
              <esmp-upload
                action="/api/files"
                type="drag"
                auto-upload
                :on-success="(res) => { onUploadLogoFile(index, res) }"
                :show-upload-list="false"
              >
                <span class="esmp-upload__label esmp-upload-drag">Выбрать файл</span>
              </esmp-upload>
            </div>
            <div class="link__option link__image ">
              <img
                :src="link.imageUrl"
                :alt="link.name"
              >
            </div>
          </div>
          <div class="link__options">
            <esmp-input
              class="link__option"
              label="Введите название"
              :value="link.name"
              @input="($event) => { setValue('name', index, $event) }"
            />
            <esmp-input
              class="link__option"
              label="Введите url"
              :value="link.productUrl"
              @input="($event) => { setValue('productUrl', index, $event) }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block__footer block__actions">
      <esmp-button
        view="outline"
        @click="save"
      >
        Сохранить
      </esmp-button>
      <esmp-button
        view="outline"
        @click="setDefault('esmps')"
      >
        Вернуть базовые настройки
      </esmp-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import Hub from '@/plugins/event-hub';

export default {
  name: 'EsmpLinks',
  data() {
    return {};
  },
  computed: {
    ...mapState('portalStyle', ['settings']),
    links: {
      get() {
        return this.settings.common.esmps;
      },
      set(newValue) {
        this.setSetting({
          path: ['common.esmps'],
          value: newValue || [],
        });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    ...mapActions('portalStyle', ['setSetting', 'saveSettings']),
    save() {
      this.setLoading({ key: 'page', value: true });
      this.saveSettings({ group: 'common', settingList: ['esmps'] })
        .then(() => {
          this.$EsmpNotify.$show('Настройки ссылок успешно сохранены', 'success');
        })
        .finally(() => {
          this.setLoading({ key: 'page', value: false });
        });
    },
    addLink() {
      const newLinks = cloneDeep(this.links);
      newLinks.push({
        imageUrl: '',
        productUrl: '',
        name: '',
      });
      this.links = newLinks;
    },
    removeLink(index) {
      this.links = this.links.filter((i, ind) => index !== ind);
    },
    async onUploadLogoFile(index, res) {
      const newValue = await res?.url;
      this.setValue('imageUrl', index, newValue);
    },
    setValue(fieldName, index, newValue) {
      const links = cloneDeep(this.links);
      links[index][fieldName] = newValue;
      this.links = links;
    },
    setDefault(section) {
      Hub.$emit('show-confirm-modal', section);
    },
  },
};
</script>

<style lang="scss">
.esmp-links {

  .block__row {
    align-items: center;
    margin-bottom: 16px;
  }

  .block__title {
    margin-bottom: 0;
  }

  &__list {
    margin-bottom: 16px;
  }

  .link {
    border: 1px solid $color-white-status;
    border-radius: $base-border-radius;
    margin-bottom: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;

    &__inner {
      width: 100%;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      align-self: flex-end;
    }

    &__switch {
      margin-right: 8px;
    }

    &__image {
      img {
        height: 53px;
      }
    }

    &__title {
      margin-right: 16px;
    }

    &__options {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &__option {
      width: 100%;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
</style>
